<script setup lang="ts">
    import { useToken } from '~/composables/useToken';
    import { getPayload } from '~/functions/getPayload';

    const { Steps, currentStep } = useSteps();

    // Initialize the token from a cookie by calling the composable.
    const token = useToken();
    const buildVersion = new Date(BUILD_VERSION).toLocaleString();

    // ToDo: remove upon complete production rollout
    function copyToken() {
        const json = JSON.stringify(token, null, 2);
        navigator.clipboard.writeText(json);
        info('Token copied to clipboard', json);
    }

    async function copyPayload() {
        const payload = await getPayload();
        const json = JSON.stringify(payload, null, 2);
        navigator.clipboard.writeText(json);
        info('Payload copied to clipboard', json);
    }
</script>

<template>
    <!-- Developer line -->
    <article
        class="container flex justify-center gap-4 text-xs items-center p-4 print:hidden"
    >
        <p class="">Build version: {{ buildVersion }}</p>
        <button class="link link-hover" @click="copyToken">Copy token</button>
        <button class="link link-hover" @click="copyPayload">
            Copy payload
        </button>
    </article>

    <!-- Language switcher -->
    <ClientOnly>
        <LangSwitcher />
    </ClientOnly>

    <!-- Navigation steps -->
    <div class="container">
        <ul class="steps my-4 w-full print:hidden">
            <li
                class="step"
                :class="{ 'step-primary': currentStep >= Steps.STEP_ONE }"
            >
                {{ $t('CheckoutNavigation.FillInDetails') }}
            </li>
            <li
                class="step"
                :class="{ 'step-primary': currentStep >= Steps.STEP_TWO }"
            >
                {{ $t('CheckoutNavigation.ChooseShipping') }}
            </li>
            <li
                class="step"
                :class="{ 'step-primary': currentStep >= Steps.STEP_THREE }"
            >
                {{ $t('CheckoutNavigation.Submit') }}
            </li>
        </ul>
    </div>

    <!-- Content -->
    <div class="flex gap-4 p-8 print:p-0 print:block container">
        <div class="w-2/3 print:hidden">
            <slot />
        </div>

        <!-- Order Overview-->
        <Receipt />
    </div>
</template>
