<script setup>
    const { locale, locales, setLocale } = useI18n();
    const switchLocalePath = useSwitchLocalePath();
</script>

<template>
    <div class="container flex gap-2 p-8 print:hidden">
        <p>Supported languages</p>

        <button
            v-for="lang in locales"
            :key="lang.code"
            @click="setLocale(lang.code)"
            class=""
            :class="{
                'font-bold disabled cursor-not-allowed': lang.code == locale,
                'link-hover': lang.code != locale,
            }"
        >
            {{ lang.name }}
        </button>
    </div>
</template>
