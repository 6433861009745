<script setup lang="ts">
    import { hasQtyMismatch } from '~/functions/hasQtyMismatch';
    import type { EnrichedOrderLine } from '~/types';
    const { isShippingFromMainWarehouse } = await useShippingConfig();
    const props = defineProps<{
        line: EnrichedOrderLine;
    }>();

    // If tags include a string like this one, we need to extract the data,
    // because this how a tinted product is decipted in the order line.
    // "linedata1000#{\"TintingCode\":\"\",\"SerialNo\":\"\",\"FandeckCode\":\"205\"}"
    const tintingInfo = computed<null | object>(() => {
        const { Tags } = props.line;

        if (!Tags) return null;

        const tag = Tags.find((tag: string) => tag.includes('linedata1000'));
        const blockingTag = Tags.find((tag: string) => tag.includes('linedata1002'));

        if (blockingTag) {
            throw showError({
                fatal: true,
                statusCode: 401,
                statusMessage: 'You are not allowed to use online-checkout when an item on the receipt contains a serial number.',
            });
        }

        if (!tag) {
            return null;
        }

        const data = JSON.parse(tag.split('#')[1]);
        return data.TintingCode;
    });
</script>

<template>
    <div class="grid auto-rows-auto text-sm mb-5">
        <p class="flex gap-1">
            <span>{{ line.Quantity }}x</span>
            <span>{{ line.Name }}</span>
            <span class="flex flex-1"></span>
            <span>{{ line.NetPrice.toFixed(2) }}</span>
        </p>
        <small class="flex justify-between text-xs opacity-55">
            <span>{{ $t('OrderReceipt.ExclVAT') }}</span>
            <span>{{ line.NetPriceExclVat.toFixed(2) }}</span>
        </small>
        <!-- Only show if quantities are not matched -->
        <!-- Render lowestQty | highestQty | multipleQty -->
        <small
            v-if="isShippingFromMainWarehouse && hasQtyMismatch(line)"
            class="flex gap-4 text-xs bg-error text-error-content font-semibold"
        >
            <span class="flex-1">{{
                $t('OrderReceipt.QuantityMismatch')
            }}</span>
            <span>
                {{ $t('OrderReceipt.Min') }}
                {{ line.mdh.salesOrderSettings[0].lowestQty }}
            </span>
            <span>
                {{ $t('OrderReceipt.Max') }}
                {{ line.mdh.salesOrderSettings[0].highestQty }}
            </span>
            <span>
                {{ $t('OrderReceipt.Multiple') }}
                {{ line.mdh.salesOrderSettings[0].multipleQty }}
            </span>
        </small>
        <p
            v-if="isShippingFromMainWarehouse"
            class="text-xs"
            :class="{
                'bg-warning text-warning-content font-semibold':
                    parseInt(line.stock.Stock) < line.Quantity,
            }"
        >
            {{ $t('OrderReceipt.UnitsInStock') }}:
            {{ parseInt(line.stock.Stock) }}
        </p>
        <!-- Show if this line is pretinted -->
        <p v-if="tintingInfo" class="text-xs justify-between">
            <span>{{ $t('OrderReceipt.TintingLabel') }}: </span>
            <span>{{ tintingInfo }}</span>
        </p>
        <p
            v-if="line.mdh.dangerousGoodsSpecifications"
            class="text-xs justify-between"
        >
            <span>{{ $t('OrderReceipt.DgLabel') }}: </span>
            <span>{{ line.mdh.dangerousGoodsSpecifications.unCode }}</span>
        </p>
    </div>
</template>
