<script setup lang="ts">
    const { isConfirmed, id } = useConfirmOrder();
    const { order, enrichedOrderlines } = await useActiveOrder();
    const customerData = useCustomerData();
    const { shippingMethod } = await useShippingConfig();
    const token = useToken();
    const posCtx = await usePosContext();

    const shippingVatAmount = computed(() => {
        if (shippingMethod.value?.tax_percent) {
            return (
                (shippingMethod.value.price *
                    shippingMethod.value.tax_percent) /
                100
            );
        }
        return 0;
    });

    const grandTotal = computed(() => {
        return (
            (
                order.Receipt.Total +
                (customerData.value.flags.isShippingPaidAsOrderLine
                    ? 0
                    : shippingMethod.value?.price || 0)
            ).toFixed(2) +
            ' ' +
            token.store_main_currency
        );
    });
</script>

<template>
    <!-- Order Overview - Receipt Card -->
    <div id="receipt-card"
        class="bg-base-200 shadow-xl p-4 rounded-xl card-bordered flex flex-col"
    >
        <!-- {{ order }} -->
        <h2 class="card-title print:m-auto print:text-2xl">
            {{ $t('OrderReceipt.OrderSummary') }}
            <span id="receiptOrderReferenceBox"></span>
        </h2> 
        <div id="receiptOrderReferenceBox"></div> 
        <!-- Show context info -->
        <div class="divider print:font-bold print:hidden">Customer</div>
        <div class="hidden font-bold mt-8 print:block print:text-xl">Customer</div>
        <div class="text-sm">
            <p>{{ customerData.billing.companyName }}</p>
            <p>{{ customerData.billing.firstName }} {{ customerData.billing.lastName }}</p>
            <br />
            <p>{{ posCtx.customerReference }}</p>
            <p>{{ posCtx.requisitionId }}</p>
            <p>{{ posCtx.projectLabel }}</p>
            <p>{{ posCtx.projectLabelDescription }}</p>
        </div>

        <div class="divider print:font-bold print:hidden">Items</div>
        <div class="hidden font-bold mt-8 print:block print:text-xl">Items</div>
        <div v-if="order.Receipt" class="my-4">
            <!-- Loop through order lines -->
            <ReceiptLine
                v-if="enrichedOrderlines?.length"
                v-for="line in enrichedOrderlines"
                :key="line.Id"
                :line="line"
            />

            <!-- Else show that receipt items are being enriched -->
            <Loader v-else>{{
                $t('OrderReceipt.EnrichingReceiptItems')
            }}</Loader>

            <!-- Divider -->
            <div class="divider"></div>

            <!-- Show shipping costs -->
            <div class="text-sm">
                <!-- Total Price Excluding VAT -->
                <div class="flex justify-between">
                    <div>{{ $t('OrderReceipt.SubtotalExclVAT') }}</div>
                    <div>
                        {{ order.Receipt.TotalExclVat.toFixed(2) }}
                    </div>
                </div>
                <!-- Total Price Excluding VAT -->
                <div class="flex justify-between">
                    <div>{{ $t('OrderReceipt.VAT') }}</div>
                    <div>
                        {{
                            (
                                order.Receipt.Total - order.Receipt.TotalExclVat
                            ).toFixed(2)
                        }}
                    </div>
                </div>

                <div class="divider"></div>

                <!-- Delivery without VAT -->
                <div
                    v-if="
                        shippingMethod?.tax_percent &&
                        !customerData.flags.isShippingPaidAsOrderLine
                    "
                    class="flex justify-between"
                >
                    <div>{{ $t('OrderReceipt.DeliveryExclVAT') }}</div>
                    <div>
                        {{
                            (shippingMethod.price - shippingVatAmount).toFixed(
                                2,
                            )
                        }}
                    </div>
                </div>
                <!-- Show VAT amount -->
                <div
                    v-if="shippingMethod?.tax_percent"
                    class="flex justify-between"
                >
                    <div>{{ $t('OrderReceipt.VATAmount') }}</div>
                    <div>
                        {{ shippingVatAmount.toFixed(2) }}
                    </div>
                </div>
                <div class="flex justify-between">
                    <div>{{ $t('OrderReceipt.DeliveryTotal') }}</div>
                    <div v-if="!customerData.flags.isShippingPaidAsOrderLine">
                        {{ (shippingMethod?.price || 0).toFixed(2) }}
                    </div>
                    <small v-else>{{
                        $t('OrderReceipt.IncludedInReceipt')
                    }}</small>
                </div>
            </div>

            <!-- Divider -->
            <div class="divider"></div>

            <!-- Totals Section -->
            <div class="text-sm print:text-xl">
                <!-- Total Price -->
                <div class="flex justify-between my-2 font-bold">
                    <div>{{ $t('OrderReceipt.GrandTotal') }}</div>
                    <div>{{ grandTotal }}</div>
                </div>
            </div>
        </div>

        <span class="flex-1"></span>
        <p class="mt-4 text-xs">
            {{
                $t('OrderReceipt.ServedBy', {
                    salespersonId: posCtx.employeeInitial,
                    store: posCtx.fullStoreId,
                    cashier: order.Receipt.Header.cashier,
                })
            }}
        </p>
    </div>
</template>
